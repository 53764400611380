// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Notifier = require("./notifier").default;

function jsNotify(prim, prim$1, prim$2) {
  Notifier(prim, prim$1, prim$2);
  
}

function success(title, text) {
  return jsNotify(title, text, "success");
}

function error(title, text) {
  return jsNotify(title, text, "error");
}

function notice(title, text) {
  return jsNotify(title, text, "notice");
}

var warn = notice;

exports.jsNotify = jsNotify;
exports.success = success;
exports.error = error;
exports.notice = notice;
exports.warn = warn;
/* ./notifier Not a pure module */
