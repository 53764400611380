// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function str(prim) {
  return prim;
}

((require("./Tooltip.css")));

function bubbleClasses(position) {
  var positionClass = position === "Top" ? "tooltip__bubble--top" : (
      position === "Bottom" ? "tooltip__bubble--bottom" : (
          position === "Left" ? "tooltip__bubble--left" : "tooltip__bubble--right"
        )
    );
  return "tooltip__bubble " + positionClass;
}

function Tooltip(Props) {
  var tip = Props.tip;
  var classNameOpt = Props.className;
  var positionOpt = Props.position;
  var disabledOpt = Props.disabled;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var position = positionOpt !== undefined ? positionOpt : "Top";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  if (disabled) {
    return children;
  } else {
    return React.createElement("div", {
                className: "tooltip " + className
              }, React.createElement("div", {
                    className: "tooltip__trigger"
                  }, children), React.createElement("div", {
                    className: bubbleClasses(position)
                  }, React.createElement("div", {
                        className: "text-white text-xs p-2 text-center leading-snug rounded bg-gray-900 whitespace-no-wrap"
                      }, tip)));
  }
}

var make = Tooltip;

exports.str = str;
exports.bubbleClasses = bubbleClasses;
exports.make = make;
/*  Not a pure module */
