// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var $$Notification = require("../Notification.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var AuthenticityToken = require("./AuthenticityToken.bs.js");

var Graphql_error = Caml_exceptions.create("GraphqlQuery.Graphql_error");

function decodeNotification(json) {
  return {
          kind: Json_decode.field("kind", Json_decode.string, json),
          title: Json_decode.field("title", Json_decode.string, json),
          body: Json_decode.field("body", Json_decode.string, json)
        };
}

function flashNotifications(obj) {
  var notifications = Js_dict.get(obj, "notifications");
  if (notifications !== undefined) {
    return List.iter((function (n) {
                  var match = n.kind;
                  var notify;
                  switch (match) {
                    case "error" :
                        notify = $$Notification.error;
                        break;
                    case "success" :
                        notify = $$Notification.success;
                        break;
                    default:
                      notify = $$Notification.notice;
                  }
                  return Curry._2(notify, n.title, n.body);
                }), Json_decode.list(decodeNotification, Caml_option.valFromOption(notifications)));
  }
  
}

function sendQuery(notifyOpt, q) {
  var notify = notifyOpt !== undefined ? notifyOpt : true;
  return fetch("/graphql", Fetch.RequestInit.make(/* Post */2, [
                        [
                          "X-CSRF-Token",
                          AuthenticityToken.fromHead(undefined)
                        ],
                        [
                          "Content-Type",
                          "application/json"
                        ]
                      ], Caml_option.some(JSON.stringify(Js_dict.fromList({
                                    hd: [
                                      "query",
                                      q.query
                                    ],
                                    tl: {
                                      hd: [
                                        "variables",
                                        q.variables
                                      ],
                                      tl: /* [] */0
                                    }
                                  }))), undefined, undefined, undefined, /* Include */2, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (resp) {
                if (resp.ok) {
                  return resp.json();
                }
                if (notify) {
                  var statusCode = String(resp.status);
                  $$Notification.error("Error " + statusCode, "Our team has been notified of this error. Please reload the page and try again.");
                }
                return Promise.reject({
                            RE_EXN_ID: Graphql_error,
                            _1: "Request failed: " + resp.statusText
                          });
              }).then(function (json) {
              var obj = Js_json.decodeObject(json);
              if (obj === undefined) {
                return Promise.reject({
                            RE_EXN_ID: Graphql_error,
                            _1: "Response is not an object"
                          });
              }
              var obj$1 = Caml_option.valFromOption(obj);
              if (notify) {
                flashNotifications(obj$1);
              }
              return Promise.resolve(Curry._1(q.parse, obj$1["data"]));
            });
}

exports.sendQuery = sendQuery;
/* Notification Not a pure module */
