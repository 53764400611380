// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");

var UnsafeFindFailed = Caml_exceptions.create("ListUtils.UnsafeFindFailed");

function isEmpty(l) {
  if (l) {
    return false;
  } else {
    return true;
  }
}

function isNotEmpty(l) {
  return !isEmpty(l);
}

function findOpt(p, l) {
  try {
    return Caml_option.some(List.find(p, l));
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === "Not_found") {
      return ;
    }
    throw exn;
  }
}

function unsafeFind(p, message, l) {
  try {
    return List.find(p, l);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === "Not_found") {
      Rollbar.error(message);
      throw {
            RE_EXN_ID: UnsafeFindFailed,
            _1: message,
            Error: new Error()
          };
    }
    throw exn;
  }
}

function distinct(l) {
  var _l = l;
  var _d = /* [] */0;
  while(true) {
    var d = _d;
    var l$1 = _l;
    if (!l$1) {
      return d;
    }
    var tail = l$1.tl;
    var head = l$1.hd;
    if (List.exists((function(head){
          return function (u) {
            return Caml_obj.caml_equal(u, head);
          }
          }(head)), d)) {
      _l = tail;
      continue ;
    }
    _d = {
      hd: head,
      tl: d
    };
    _l = tail;
    continue ;
  };
}

function swapDown(e, l) {
  var _prev = /* [] */0;
  var _l = l;
  while(true) {
    var l$1 = _l;
    var prev = _prev;
    if (!l$1) {
      return prev;
    }
    var tail = l$1.tl;
    var head = l$1.hd;
    if (tail && Caml_obj.caml_equal(head, e)) {
      return Pervasives.$at(prev, {
                  hd: tail.hd,
                  tl: {
                    hd: head,
                    tl: tail.tl
                  }
                });
    }
    _l = tail;
    _prev = Pervasives.$at(prev, {
          hd: head,
          tl: /* [] */0
        });
    continue ;
  };
}

function swapUp(e, l) {
  return List.rev(swapDown(e, List.rev(l)));
}

function swap(up, e, l) {
  if (up) {
    return swapUp(e, l);
  } else {
    return swapDown(e, l);
  }
}

exports.UnsafeFindFailed = UnsafeFindFailed;
exports.isEmpty = isEmpty;
exports.isNotEmpty = isNotEmpty;
exports.findOpt = findOpt;
exports.unsafeFind = unsafeFind;
exports.distinct = distinct;
exports.swapDown = swapDown;
exports.swapUp = swapUp;
exports.swap = swap;
/* No side effect */
