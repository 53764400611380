// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var PfIcon = require("../packages/pf-icon/src/PfIcon.bs.js");

var transformIcons = PfIcon.transformIcons;

var make = PfIcon.make;

exports.transformIcons = transformIcons;
exports.make = make;
/* PfIcon Not a pure module */
