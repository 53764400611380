// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function nullUnless(element, condition) {
  if (condition) {
    return element;
  } else {
    return null;
  }
}

function nullIf(element, condition) {
  if (condition) {
    return null;
  } else {
    return element;
  }
}

exports.nullUnless = nullUnless;
exports.nullIf = nullIf;
/* No side effect */
