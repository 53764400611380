// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../Icon.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ScrollLock = require("../utils/ScrollLock.bs.js");

((require("./EditorDrawer.css")));

function drawerClasses(size, level, previousLevel) {
  var sizeClass;
  switch (size) {
    case /* Small */0 :
        sizeClass = " editor-drawer--small";
        break;
    case /* Normal */1 :
        sizeClass = "";
        break;
    case /* Large */2 :
        sizeClass = " editor-drawer--large";
        break;
    
  }
  var pLevel = previousLevel.current;
  var animationClass = level !== 0 ? (
      level !== 1 ? " editor-drawer--l0" : (
          pLevel !== 0 ? (
              pLevel !== 1 ? " editor-drawer--l0" : " editor-drawer--l1"
            ) : " editor-drawer--l0-to-l1"
        )
    ) : (
      pLevel !== 0 && pLevel === 1 ? " editor-drawer--l1-to-l0" : " editor-drawer--l0"
    );
  previousLevel.current = level;
  return "editor-drawer" + sizeClass + animationClass;
}

function EditorDrawer(Props) {
  var closeDrawerCB = Props.closeDrawerCB;
  var closeButtonTitleOpt = Props.closeButtonTitle;
  var sizeOpt = Props.size;
  var closeIconClassNameOpt = Props.closeIconClassName;
  var levelOpt = Props.level;
  var children = Props.children;
  var closeButtonTitle = closeButtonTitleOpt !== undefined ? closeButtonTitleOpt : "Close Editor";
  var size = sizeOpt !== undefined ? sizeOpt : /* Normal */1;
  var closeIconClassName = closeIconClassNameOpt !== undefined ? closeIconClassNameOpt : "if i-times-regular";
  var level = levelOpt !== undefined ? levelOpt : 0;
  var previousLevel = React.useRef(level);
  React.useEffect(function () {
        ScrollLock.activate(undefined);
        return (function (param) {
                  return ScrollLock.deactivate(undefined);
                });
      });
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "blanket"
                }), React.createElement("div", {
                  className: drawerClasses(size, level, previousLevel)
                }, React.createElement("div", {
                      className: "editor-drawer__close hidden md:block absolute"
                    }, React.createElement("button", {
                          className: "flex items-center justify-center bg-white text-gray-600 font-bold py-3 px-5 rounded-l-full rounded-r-none hover:text-gray-700 focus:outline-none mt-4",
                          title: closeButtonTitle,
                          onClick: (function (e) {
                              e.preventDefault();
                              return Curry._1(closeDrawerCB, undefined);
                            })
                        }, React.createElement(Icon.make, {
                              className: closeIconClassName + " text-xl"
                            }))), React.createElement("div", {
                      className: "w-full relative overflow-y-scroll"
                    }, React.createElement("div", {
                          className: "md:hidden absolute right-0 pt-3 pr-4 z-50"
                        }, React.createElement("button", {
                              className: "flex items-center justify-center w-10 h-10 bg-gray-300 text-gray-800 font-bold p-3 rounded-full hover:bg-gray-100 hover:text-gray-900 focus:outline",
                              title: closeButtonTitle,
                              onClick: (function (e) {
                                  e.preventDefault();
                                  return Curry._1(closeDrawerCB, undefined);
                                })
                            }, React.createElement(Icon.make, {
                                  className: closeIconClassName + " text-xl"
                                }))), children)));
}

var make = EditorDrawer;

exports.drawerClasses = drawerClasses;
exports.make = make;
/*  Not a pure module */
