// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");

((require("./SkeletonLoading.css")));

function card(classNameOpt, param) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: "skeleton-body-container pt-4 w-full mx-auto" + className
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__card px-5 py-6 bg-white rounded-lg shadow"
                    }, React.createElement("div", {
                          className: "flex items-center"
                        }, React.createElement("div", {
                              className: "flex-1"
                            }, React.createElement("div", {
                                  className: "skeleton-placeholder__line-sm w-5/6 skeleton-animate"
                                }), React.createElement("div", {
                                  className: "skeleton-placeholder__line-sm mt-4 w-4/6 skeleton-animate"
                                })), React.createElement("div", {
                              className: "skeleton-placeholder__line-sm w-1/6 skeleton-animate"
                            })))));
}

function userCard(param) {
  return React.createElement("div", {
              className: "skeleton-body-container pt-4 w-full mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper px-2 lg:px-0"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__card px-5 py-6 bg-white rounded-lg shadow"
                    }, React.createElement("div", {
                          className: "flex items-center"
                        }, React.createElement("div", {
                              className: "w-14 h-14 bg-gray-100 rounded-full mr-4 skeleton-animate"
                            }), React.createElement("div", {
                              className: "flex-1"
                            }, React.createElement("div", {
                                  className: "skeleton-placeholder__line-sm w-2/6 skeleton-animate"
                                }), React.createElement("div", {
                                  className: "skeleton-placeholder__line-sm mt-4 w-3/6 skeleton-animate"
                                })), React.createElement("div", {
                              className: "skeleton-placeholder__line-sm w-1/6 skeleton-animate"
                            })))));
}

function heading(param) {
  return React.createElement("div", {
              className: "skeleton-body-container w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper mt-8 px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__line-md mt-4 w-3/6 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 w-4/6 skeleton-animate"
                    })));
}

function codeBlock(param) {
  return React.createElement("div", {
              className: "skeleton-body-container w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper max-w-xs mt-8 px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "flex"
                    }, React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mr-3 w-2/6 skeleton-animate"
                        }), React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mr-3 w-2/6 skeleton-animate"
                        }), React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mr-3 w-4 skeleton-animate"
                        })), React.createElement("div", {
                      className: "flex ml-6"
                    }, React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mt-4 mr-3 w-3/6 skeleton-animate"
                        }), React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mt-4 mr-3 w-3/6 skeleton-animate"
                        })), React.createElement("div", {
                      className: "flex ml-6"
                    }, React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mt-4 mr-3 w-2/6 skeleton-animate"
                        }), React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mt-4 mr-3 w-1/6 skeleton-animate"
                        }), React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mt-4 mr-3 w-2/6 skeleton-animate"
                        })), React.createElement("div", {
                      className: "flex"
                    }, React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mt-4 mr-3 w-16 skeleton-animate"
                        }), React.createElement("div", {
                          className: "skeleton-placeholder__line-sm mt-4 mr-3 w-6 skeleton-animate"
                        }))));
}

function profileCard(param) {
  return React.createElement("div", {
              className: "skeleton-body-container w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper max-w-sm mt-8 px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "flex items-center"
                    }, React.createElement("div", {
                          className: "w-14 h-14 bg-gray-100 rounded-full mr-4 skeleton-animate"
                        }), React.createElement("div", {
                          className: "flex-1"
                        }, React.createElement("div", {
                              className: "skeleton-placeholder__line-sm w-3/6 skeleton-animate"
                            }), React.createElement("div", {
                              className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                            })))));
}

function image(param) {
  return React.createElement("div", {
              className: "skeleton-body-container w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper mt-8 px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__image mt-5 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mx-auto mt-4 w-3/6 skeleton-animate"
                    })));
}

function paragraph(param) {
  return React.createElement("div", {
              className: "skeleton-body-container w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper mt-8 px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 w-4/6 skeleton-animate"
                    })));
}

function contents(param) {
  return React.createElement("div", {
              className: "skeleton-body-container w-full pb-4 mx-auto"
            }, React.createElement("div", {
                  className: "skeleton-body-wrapper mt-8 px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__line-md mt-4 w-3/6 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 w-4/6 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__image mt-5 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mx-auto mt-4 w-3/6 skeleton-animate"
                    })), React.createElement("div", {
                  className: "skeleton-body-wrapper mt-8 px-3 lg:px-0"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 skeleton-animate"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-sm mt-4 w-4/6 skeleton-animate"
                    })));
}

function multiple(count, element) {
  return $$Array.mapi((function (key, element) {
                return React.createElement("div", {
                            key: String(key)
                          }, element);
              }), Caml_array.caml_make_vect(count, element));
}

exports.card = card;
exports.userCard = userCard;
exports.heading = heading;
exports.codeBlock = codeBlock;
exports.profileCard = profileCard;
exports.image = image;
exports.paragraph = paragraph;
exports.contents = contents;
exports.multiple = multiple;
/*  Not a pure module */
