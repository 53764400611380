// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function toArray(t) {
  if (typeof t === "number") {
    return [];
  } else {
    return t._0;
  }
}

exports.toArray = toArray;
/* No side effect */
