// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_array = require("bs-platform/lib/js/caml_array.js");

function handleScrollLock(add) {
  var classes = add ? "overflow-hidden" : "";
  var body = Array.prototype.slice.call(document.getElementsByTagName("body"));
  Caml_array.get(body, 0).className = classes;
  
}

function activate(param) {
  return handleScrollLock(true);
}

function deactivate(param) {
  return handleScrollLock(false);
}

exports.handleScrollLock = handleScrollLock;
exports.activate = activate;
exports.deactivate = deactivate;
/* No side effect */
