// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var ListUtils = require("./ListUtils.bs.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var $$Notification = require("../Notification.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");

var UnsafeFindFailed = Caml_exceptions.create("ArrayUtils.UnsafeFindFailed");

function copyAndSort(f, t) {
  var cp = t.slice();
  return cp.sort(Curry.__2(f));
}

function copyAndPush(e, t) {
  var copy = t.slice();
  copy.push(e);
  return copy;
}

function isEmpty(a) {
  return a.length === 0;
}

function isNotEmpty(a) {
  return a.length !== 0;
}

function unsafeFind(p, message, l) {
  var e = l.find(Curry.__1(p));
  if (e !== undefined) {
    return e;
  }
  Rollbar.error(message);
  $$Notification.error("An unexpected error occurred", "Our team has been notified about this error. Please try reloading this page.");
  throw {
        RE_EXN_ID: UnsafeFindFailed,
        _1: message,
        Error: new Error()
      };
}

function flatten(t) {
  return $$Array.of_list(List.flatten($$Array.to_list(t)));
}

function flattenV2(a) {
  return a.reduce((function (flat, next) {
                return flat.concat(next);
              }), []);
}

function distinct(t) {
  return $$Array.of_list(ListUtils.distinct($$Array.to_list(t)));
}

function sort_uniq(f, t) {
  return $$Array.of_list(List.sort_uniq(f, $$Array.to_list(t)));
}

function getOpt(a, i) {
  try {
    return Caml_option.some(Caml_array.get(i, a));
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === "Not_found") {
      return ;
    }
    throw exn;
  }
}

function swapUp(i, t) {
  if (i <= 0 || i >= t.length) {
    Rollbar.warning("Index to swap out of bounds in array!");
    return t;
  }
  var copy = t.slice();
  Caml_array.set(copy, i, Caml_array.get(t, i - 1 | 0));
  Caml_array.set(copy, i - 1 | 0, Caml_array.get(t, i));
  return copy;
}

function swapDown(i, t) {
  return swapUp(i + 1 | 0, t);
}

function last(t) {
  return t[t.length - 1 | 0];
}

exports.UnsafeFindFailed = UnsafeFindFailed;
exports.copyAndSort = copyAndSort;
exports.copyAndPush = copyAndPush;
exports.isEmpty = isEmpty;
exports.isNotEmpty = isNotEmpty;
exports.unsafeFind = unsafeFind;
exports.flatten = flatten;
exports.flattenV2 = flattenV2;
exports.distinct = distinct;
exports.sort_uniq = sort_uniq;
exports.getOpt = getOpt;
exports.swapUp = swapUp;
exports.swapDown = swapDown;
exports.last = last;
/* Notification Not a pure module */
